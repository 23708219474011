<template>
    <div style="text-align: left; width: fit-content; background-color: white;" id="inputDiv">
        <div>
            <b-sidebar id="sidebar-help" title="Help" shadow right>
                <div style="padding:1rem">
                    <h2 class="pb-5" style="font-weight: 600">Forward Curves</h2>
                    <h3 class="pb-3">Tools and Filters</h3>
                    <h4><b>Chart type: </b>Averages shows the selected future forward curves together with the averages of the past 5 and 15 years of forward curves.</h4>
                    <h4><b>Aligned Series: </b><span class="d-flex justify-content-center">shows the current forward curve along with past years' forward curves, shifted forward in time so they all appear in the same year.</span></h4>
                    <h4><b>Unaligned Series: </b>shows the current forward curve along with past years' forward curves.</h4>
                    <h4><b>Show/Hide Label: </b>show or hide information labels on the chart.</h4>
                    <hr>
                    <h4>Need support? Visit the Support page or <a href="/support">click here</a></h4>
                </div>
            </b-sidebar>
        </div>

        <b-form inline style="width: fit-content; border: 1px solid gray; padding: 2px; white-space: nowrap;
                                 background-color: #e7edf5; margin: 2px 1px 1px -11px; border-radius: 3px;">
                        <b-form-select  v-model="commodity" :options="commodityOptions" style="border:1px solid gray; margin: 2px" ></b-form-select>

            <span  style="margin-left: 15px; font-weight: bold"> chart type: 
                <b-form-select size="sm" v-model="forwardCurveStudy" :options="studyOptions" style="border:1px solid gray;" ></b-form-select>
            </span>

            <span style="margin-left: 15px; font-weight: bold"> show curves from the past 
                <b-form-select size="sm" v-model="y1" :options="yearOptions"  
                               style="border:1px solid gray; " ></b-form-select>
                years</span> 


            <b-button-group style="border: 1px solid gray; padding: 2px; white-space: nowrap; background-color: white;
                                     margin-right: 2px; margin-left: 25px; border-radius: 3px;"> 
                <toggle-single-throw v-bind:namespace="namespace" v-bind:property="'normalization'" v-bind:label="'normalization'" style="margin: 0px;"></toggle-single-throw>
            </b-button-group>

            <b-button-group v-if="this.forwardCurveStudy !== 'unaligned'" style="border: 1px solid #555555; padding: 2px; white-space: nowrap;
                                                                                 background-color: white; margin-right: 2px; border-radius: 3px;"> 
                <b-button v-on:click="$root.$emit('change-period', '1')" id="3-MM" size="sm" style="margin-left:0px; background-color: darkgray; color: white;">1 yr</b-button>
                <b-button v-on:click="$root.$emit('change-period', '2')" id="6-MM" size="sm" style="margin-left:2px; background-color: darkgray; color: white;">2 yr</b-button>
                <b-button v-on:click="$root.$emit('change-period', '15')" id="YYYY" size="sm" style="margin-left:2px; background-color: darkgray; color: white;">MAX</b-button>
           </b-button-group>
                        
            <b-button-group style="border: 1px solid #555555; padding: 2px; white-space: nowrap; background-color: white; margin-right: 2px; border-radius: 3px;"> 
                <balloon-toggle v-bind:namespace="namespace" style="margin: 0px"></balloon-toggle>

                <b-button size="sm" v-b-toggle.sidebar-help variant="warning" style="border: 1px solid darkgray; margin-left: 2px">Help</b-button>
            </b-button-group>
        </b-form>

        
    </div>
</template>

<script>
    import balloonToggle from '@/components/balloon-toggle';
    import toggleSingleThrow from '@/components/toggle-single-throw';

    export default {
        components: {
            toggleSingleThrow, balloonToggle
        },
        mounted: function () {
           // console.log("forward-curves-input.vue starting.");

             let observer = new ResizeObserver(entries => {
               // console.log("ResizeObserver() starting. this.namespace=", this.namespace, " activeModuleName=", this.$store.getters['activeModuleName']);
                if (this.$store.getters['activeModuleName'] === this.namespace) {
                   // console.log("checking input height.");
                    for (let entry of entries) {
                        // console.log("entry=", entry);
                        // Now do something with the resized element
                       // console.log("entry.contentRect.height=", entry.contentRect.height);
                        this.$store.commit(this.namespace + '/browserSideOnly/setInputDivHeight', entry.contentRect.height);
                    }
                }
            });
            observer.observe(document.querySelector('#' + this.namespace + 'InputDiv'));  
        },
        props: ['namespace'],
        data: function () {
            return {
                contracts: [],
                monthsWithAverageOrCurrent: "",
                series: [],
                data: [],
                dates: [],
                lastDate: "",
                studyOptions: [
                    {value: 'aligned', text: 'aligned'},
                    {value: 'averages', text: 'averages'},
                    {value: 'unaligned', text: 'unaligned'}
                ]
            }
        },
        computed: {
            commodity: {
                get() {
                    let commodity = this.$store.getters[this.namespace + '/c'][0];
                    console.log("commodity=", commodity);
                    return  commodity;
                },
                set(commodity) {
                    this.$store.dispatch(this.namespace + '/changeCommodityLeg', {commodity: commodity, index: 0});
                }
            },
            commodityOptions() {
                return this.$store.getters["siteData/commoditiesArray"].map(x => {
                    return {value: x.symbol, text: x.name};
                });
            },
            y1: {
                get() {
                    return this.$store.state[this.namespace].y1;
                },
                set(y1) {
                    this.$store.commit(this.namespace + '/setY1', y1);
                }
            },
            yearOptionsLength(){
                console.log("this.$store.state[this.namespace].yearOptionsLength=", this.$store.state[this.namespace].yearOptionsLength);
                return this.$store.state[this.namespace].yearOptionsLength;
            },
            yearOptions(){
                console.log("this.yearOptionsLength=", this.yearOptionsLength);
                return Array(this.yearOptionsLength).fill(null).map((x, i) => ({value: i + 1, text: i + 1}))
            },
            forwardCurveStudy: {
                get() {
                   // console.log("this.$store.state[this.namespace].forwardCurveStudy=", this.$store.state[this.namespace].forwardCurveStudy);
                    return this.$store.state[this.namespace].forwardCurveStudy;
                },
                set(forwardCurveStudy) {
                    this.$store.commit(this.namespace + '/setForwardCurveStudy', forwardCurveStudy);
                }
            }
        }
    };

</script>
